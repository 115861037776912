<template>
  <div
    ref="mapWrapper"
    :style="props.style"
    :class="`${props.class ? props.class : ''} ${$vuetify.theme.current.dark ? 'bg-dark' : 'bg-white'}`"
    class="w-100 position-relative d-flex flex-column"
    style="overflow-x: hidden"
  >
    <div class="flex-grow-1 d-flex flex-column relative-position">
      <div ref="mapReference" class="w-100 flex-grow-1"/>

      <div
        v-if="showMapInnerControls"
        class="position-absolute d-flex"
        style="margin: 10px; left: 0; top: 0; z-index: 999;"
        :style="`${drawerOpen ? 'margin-left: 410px;' : ''}`"
      >
        <div class="d-flex flex-column align-start" style="gap: 8px;">
          <v-tooltip :text="$t('map.layers')" location="end">
            <template #activator="{ props }">
              <v-btn
                v-bind="props"
                :color="mapControlTab === MapControlTab.LAYERS ? 'primary' : 'surface'"
                rounded="sm"
                density="compact"
                icon
                :aria-label="$t('map.layers')"
                style="width: 40px; min-height: 40px"
                @click="() => mapControlTab = mapControlTab === MapControlTab.LAYERS ? MapControlTab.CONTROLS : MapControlTab.LAYERS"
              >
                <v-badge v-if="pdokGeoLayers.some(layer => activeMapLayers.has(layer)) || activeDuoppLayers.some(layer => activeMapLayers.has(layer.key))" dot color="error">
                  <v-icon icon="layers"/>
                </v-badge>

                <v-icon v-else icon="layers"/>
              </v-btn>
            </template>
          </v-tooltip>

          <v-tooltip :text="$t('map.filter')" location="end">
            <template #activator="{ props }">
              <v-btn
                v-bind="props"
                :color="mapControlTab === MapControlTab.FILTERS ? 'primary' : 'surface'"
                rounded="sm"
                density="compact"
                icon
                :aria-label="$t('map.filter')"
                style="width: 40px; min-height: 40px"
                @click="mapControlTab = mapControlTab === MapControlTab.FILTERS ? MapControlTab.CONTROLS : MapControlTab.FILTERS"
              >
                <v-badge v-if="categoryFilters.length > 0 || cityFilters.length > 0" dot color="error">
                  <v-icon :icon="$t('assets.icon')"/>
                </v-badge>

                <v-icon v-else :icon="$t('assets.icon')"/>
              </v-btn>
            </template>
          </v-tooltip>

          <v-tooltip :text="showNearby ? $t('map.hideNearby') : $t('map.showNearby')" location="end">
            <template #activator="{ props }">
              <v-btn
                v-if="!disableShowNearby"
                v-bind="props"
                color="surface"
                rounded="sm"
                density="compact"
                icon
                :aria-label="showNearby ? $t('map.hideNearby') : $t('map.showNearby')"
                style="width: 40px; min-height: 40px"
                @click="() => showNearby = !showNearby"
              >
                <v-icon :icon="showNearby ? 'explore' : 'explore_off'"/>
              </v-btn>
            </template>
          </v-tooltip>

          <v-tooltip :text="$t('map.type')" location="end">
            <template #activator="{ props }">
              <v-btn
                v-bind="props"
                color="surface"
                rounded="sm"
                density="compact"
                icon
                :aria-label="$t('map.type')"
                style="width: 40px; min-height: 40px"
                @click="togglePdokLayer(DefaultLayer.SATELLITE, 'hwh', 'luchtfotorgb')"
              >
                <v-icon :icon="activeMapLayers.has(DefaultLayer.SATELLITE) ? 'satellite' : 'landscape'"/>
              </v-btn>
            </template>
          </v-tooltip>

          <v-tooltip :text="$t('map.recenter')" location="end">
            <template #activator="{ props }">
              <v-btn
                v-bind="props"
                color="surface"
                rounded="sm"
                density="compact"
                icon
                :aria-label="$t('map.recenter')"
                style="width: 40px; min-height: 40px"
                @click="recenter"
              >
                <v-icon icon="my_location"/>
              </v-btn>
            </template>
          </v-tooltip>
        </div>
      </div>

      <div class="position-absolute" style="margin: 10px; top: 0; right: 0; z-index: 999">
        <asset-info-window v-if="selectedAsset" :asset="selectedAsset" @close="onAssetInfowindowClosed" />
        <span v-else class="d-flex flex-wrap" style="gap: 8px;">
          <slot name="absolute-top-right" />

          <v-tooltip :text="$t('fullscreen')" location="start">
            <template #activator="{ props }">
              <v-btn
                v-if="showMapInnerControls && fullscreenCapable"
                v-bind="props"
                color="surface"
                rounded="sm"
                density="compact"
                icon="fullscreen"
                :aria-label="$t('fullscreen')"
                style="width: 40px; min-height: 40px"
                @click="() => toggleFullscreen()"
              />
            </template>
          </v-tooltip>
        </span>
      </div>

      <div class="position-absolute" style="margin: 10px; margin-bottom: 24px; bottom: 0; right: 0; z-index: 999">
        <v-card rounded="sm">
          <v-btn
            color="surface"
            rounded="sm"
            density="compact"
            icon
            :aria-label="$t('googleMaps.zoomIn')"
            style="min-width: 40px; min-height: 40px"
            @click="() => map?.setZoom((map.getZoom() ?? 0) + 1)"
          >
            <v-icon icon="add"/>
          </v-btn>

          <v-divider />

          <v-btn
            color="surface"
            rounded="sm"
            density="compact"
            icon
            :aria-label="$t('googleMaps.zoomOut')"
            style="min-width: 40px; min-height: 40px"
            @click="() => map?.setZoom((map.getZoom() ?? 0) - 1)"
          >
            <v-icon icon="remove"/>
          </v-btn>
        </v-card>
      </div>

      <side-drawer
        :title="$t('map.layers')"
        :model-value="mapControlTab === MapControlTab.LAYERS"
        style="filter: opacity(0.95)"
        @update:model-value="() => mapControlTab = MapControlTab.CONTROLS"
      >
        <v-list :opened="['general', 'geoLayersDuopp', 'geoLayers']">
          <v-list-group value="general">
            <template #activator="{ props }">
              <v-list-item v-bind="props" :title="$t('general')"/>
            </template>

            <template #default>
              <v-list-item class="py-0">
                <v-checkbox v-model="showRelationLines" density="comfortable" hide-details :label="$t('map.showRelationLines')" />
              </v-list-item>
            </template>
          </v-list-group>

          <v-list-group v-if="settings?.geoLayersDuoPP" value="geoLayersDuopp">
            <template #activator="{ props }">
              <v-list-item v-bind="props" :title="'Duopp GWSW'"/>
            </template>

            <template #default>
              <v-list-item v-for="layer of activeDuoppLayers" :key="layer.key" class="py-0">
                <v-checkbox
                  :model-value="activeMapLayers.has(layer.key)"
                  hide-details
                  density="comfortable"
                  :label="$te(`geoLayersDuoPP.${ layer.key }`) ? $t(`geoLayersDuoPP.${ layer.key }`) : layer.name"
                  @click="() => toggleDuoppLayer(layer.key)"
                />
                <v-img v-if="activeMapLayers.has(layer.key)" :src="generateLayerLegendUrl(layer.key, duoppKey)" :alt="`Legend: ${ layer.name }`" width="auto" />
              </v-list-item>
            </template>
          </v-list-group>

          <v-list-group v-if="settings?.geoLayers" value="geoLayers">
            <template #activator="{ props }">
              <v-list-item v-bind="props" :title="'PDOK'"/>
            </template>

            <template #default>
              <v-list-item v-for="layer of pdokGeoLayers" :key="layer" class="py-0">
                <v-checkbox
                  hide-details
                  :model-value="activeMapLayers.has(layer)"
                  density="comfortable"
                  :label="$te(`geoLayers.${layer}`) ? $t(`geoLayers.${layer}`) : layer"
                  @click="() => togglePdokLayer(layer, 'rioned', 'beheerstedelijkwater')"
                />
                <v-img v-if="activeMapLayers.has(layer)" :src="generateLayerLegendUrl(layer, undefined, $t(`geoLayers.${layer}`))" :alt="`Legend: ${ layer }`" width="auto" />
              </v-list-item>
            </template>
          </v-list-group>
        </v-list>
      </side-drawer>

      <side-drawer
        :title="$t('map.filter')"
        :model-value="mapControlTab === MapControlTab.FILTERS"
        style="filter: opacity(0.95)"
        @update:model-value="() => mapControlTab = MapControlTab.CONTROLS"
      >
        <slot name="filter" :assets="assets">
          <v-list :opened="['categories', 'cities']">
            <v-list-group value="categories">
              <template #activator="{ props }">
                <v-list-item v-bind="props">
                  <template #title>
                    <span>{{ $t('categories') }}</span>
                    <v-badge v-if="categoryFilters.length > 0" inline color="error" :content="categoryFilters.length" />
                  </template>
                </v-list-item>
              </template>

              <template #default>
                <v-list-item v-for="{ _id, description } of categories" :key="_id" class="py-0">
                  <v-checkbox v-model="categoryFilters" hide-details density="comfortable" :label="description" :value="_id" />
                  <template #append>({{ allAssets.filter((asset) => asset.category === _id).length }})</template>
                </v-list-item>
              </template>
            </v-list-group>

            <v-list-group value="cities">
              <template #activator="{ props }">
                <v-list-item v-bind="props">
                  <template #title>
                    <span>{{ $t('cities') }}</span>
                    <v-badge v-if="cityFilters.length > 0" inline color="error" :content="cityFilters.length" />
                  </template>
                </v-list-item>
              </template>

              <template #default>
                <v-list-item v-for="{ description } of cities" :key="description" class="py-0">
                  <v-checkbox v-model="cityFilters" hide-details density="comfortable" :label="description" :value="description" />
                  <template #append>({{ allAssets.filter((asset) => asset.location?.city === description).length }})</template>
                </v-list-item>
              </template>
            </v-list-group>
          </v-list>
        </slot>
      </side-drawer>

      <side-drawer
        right
        :title="$t('map.layerFeatures')"
        style="filter: opacity(0.95)"
        :model-value="mapFeatures.length > 0"
        @update:model-value="() => mapFeatures = []"
      >
        <v-list>
          <v-list-group v-for="mapFeature in mapFeatures" :key="mapFeature.layerKey">
            <template #activator="{ props }">
              <v-list-item v-bind="props" :title="$t(`geoLayers.${mapFeature.layerKey}`)"/>
            </template>

            <template #default>
              <div v-for="(feature, index) of mapFeature.features" :key="index">
                <v-list-item v-for="([propertyKey, propertyValue]) of Object.entries(feature.properties).filter(([_key, value]) => !!value)" :key="propertyKey" :title="propertyKey" :subtitle="propertyValue"/>
              </div>
            </template>
          </v-list-group>
        </v-list>
      </side-drawer>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useTheme } from "vuetify"
import isEqual from "lodash-es/isEqual"
import L from "leaflet"
import "leaflet.markercluster"
import { generateLayerLegendUrl } from "~~/utils/map-helpers"

enum MapControlTab {
  CONTROLS = "CONTROLS",
  LAYERS = "LAYERS",
  FILTERS = "FILTERS",
}

enum DefaultLayer {
  BASE = "base",
  MARKER = "marker",
  SATELLITE = "Actueel_orthoHR",
  RELATION = "relation",
}

interface MarkerAsset extends LookupAsset {
  iconSettings: IconSettings
}

const $theme = useTheme()
const authStore = useAuthStore()
const organizationStore = useOrganizationStore()
const assetStore = useAssetStore()
const cityStore = useCityStore()

const { showMapInnerControls } = useUserPreferences()

const { organizations: currentOrganizationIds } = storeToRefs(authStore)
const { categories } = storeToRefs(assetStore)
const { items: cities } = storeToRefs(cityStore)

const { hasScope, hasOrganizationWithScope } = authStore

const props = defineProps<{
  style?: unknown
  class?: unknown
  disableShowNearby?: boolean
  center?: { lng: number; lat: number }
  zoom?: number,
  mainAsset?: LookupAsset
  centerOnMainAsset?: boolean
  showMainAssetRelations?: boolean
  assets?: Array<LookupAsset>
  loading?: boolean
}>()

const { mainAsset, showMainAssetRelations, assets, center } = toRefs(props)

onMounted(() => {
  L.MarkerClusterGroup.include({
    _getExpandedVisibleBounds: function() {
      if (!this._map) return

      if (!this.options.removeOutsideVisibleBounds) {
        return this._mapBoundsInfinite
      } else if (L.Browser.mobile) {
        return this._checkBoundsMaxLat(this._map.getBounds())
      }

      return this._checkBoundsMaxLat(this._map.getBounds().pad(1))
    },
    _moveEnd: function() {
      if (this._inZoomAnimation || !this._map) return

      const newBounds = this._getExpandedVisibleBounds()
      this._topClusterLevel._recursivelyRemoveChildrenFromMap(this._currentShownBounds, Math.floor(this._map.getMinZoom()), this._zoom, newBounds)
      this._topClusterLevel._recursivelyAddChildrenToMap(null, Math.round(this._map._zoom), newBounds)
      this._currentShownBounds = newBounds

      return
    },
  })

  L.GridLayer.include({
    _resetView: function(e: { pinch: boolean; flyTo: boolean }) {
      if (!this._map) return

      const animating = e && (e.pinch || e.flyTo)
      this._setView(this._map.getCenter(), this._map.getZoom(), animating, animating)
    },
  })
})

onUnmounted(() => {
  if (map) {
    map.off("click")
    map.remove()
    map = null
  }
})

useLazyAsyncData("city-filters", () => cityStore.getItems(false))
useLazyAsyncData("categories", () => assetStore.getCategories())
const { data: settings } = useLazyAsyncData("map-settings", () => organizationStore.getSettings(currentOrganizationIds.value![0]))

// Used for leaflet map
const mapWrapper = ref<HTMLElement>()
const mapReference = ref<HTMLElement | null>(null)
let map: L.Map | null = null

const activeMapLayers = ref(new Map<string, L.Layer>())
const internalCenter = ref(center?.value || defaultCoords)
const showNearby = ref(false)
const nearbyAssets = ref<Array<LookupAsset>>([])
const mapControlTab = ref(MapControlTab.CONTROLS)

const categoryFilters = ref<Array<string>>([])
const cityFilters = ref<Array<string>>([])

const selectedAsset = ref<MarkerAsset | null>(null)
const selectedMarker = ref<L.Marker | null>(null)
const mainAssetRelations = ref<Array<PopulatedAssetRelation>>([])

const mapFeatures = ref<Array<MapFeatureData>>([])
const showRelationLines = useLocalStorage("show-relation-lines", true)
const pdokGeoLayers = ["BeheerGebied", "BeheerPut", "BeheerLeiding", "BeheerPomp", "BeheerLozing", "BeheerBouwwerk", "AansluitingLeiding", "AansluitingPunt"]

const fullscreenCapable = computed(() => typeof document !== 'undefined' && document.fullscreenEnabled && mapWrapper.value)
const drawerOpen = computed(() => mapControlTab.value === MapControlTab.LAYERS || mapControlTab.value === MapControlTab.FILTERS)
const activeDuoppLayers = computed(() => settings.value?.geoDuoPPCategories.filter((layer) => layer.enabled) ?? [])
const activePdokLayers = computed(() => pdokGeoLayers.filter((layer) => activeMapLayers.value.has(layer)))
const duoppKey = computed(() => settings.value?.geoLayersDuoPPOrganizationName)

const allAssets = computed(() => {
  const candidateAssets: Array<LookupAsset> = []

  if (mainAsset?.value) {
    candidateAssets.push(mainAsset?.value)
  }

  if (mainAsset?.value && showMainAssetRelations?.value) {
    candidateAssets.push(
      ...mainAssetRelations.value.map((relation) => (relation.asset1._id === mainAsset?.value!._id ? relation.asset2 : relation.asset1))
    )
  }

  if (assets?.value) {
    const existingIds = candidateAssets.map((asset) => asset._id) || []
    candidateAssets.push(...(assets?.value.filter((asset) => !existingIds.includes(asset._id)) || []))
  }

  if (showNearby.value) {
    const existingIds = candidateAssets.map((asset) => asset._id) || []
    const nearby = nearbyAssets.value.filter((asset) => !existingIds.includes(asset._id))

    candidateAssets.push(...nearby)
  }

  return candidateAssets.filter((asset) => !!asset.location?.gps)
})

const filteredAssets = computed(() =>
  allAssets.value.filter((asset) => {
    if (mainAsset?.value && asset._id === mainAsset?.value._id) {
      return true
    }

    if (categoryFilters.value.length && !categoryFilters.value.includes(asset.category || "")) {
      return false
    }

    if (cityFilters.value.length && !cityFilters.value.includes(asset.location?.city || "")) {
      return false
    }

    return true
  })
)

const assetsForMarkers = computed(() => {
  const categoryMapper = new Map<string, AssetCategory>()
  categories.value.forEach((category) => categoryMapper.set(category._id, category))

  return filteredAssets.value.map((asset) => {
    const category = categoryMapper.get(asset.category!)

    return {
      ...asset,
      iconSettings: {
        color: category?.marker.color,
        size: asset._id === mainAsset?.value?._id ? MarkerSize.LARGE : category?.marker.size,
        label: category?.marker.description,
        showBadge: !!(asset.ticketCount?.action || asset.ticketCount?.inspection || asset.ticketCount?.malfunction),
      }
    } as MarkerAsset
  })
})

const getAssetLatLng = (asset: LookupAsset) => {
  if (!asset.location?.gps) return L.latLng(internalCenter.value.lat, internalCenter.value.lng)

  return L.latLng(asset.location.gps.coordinates[1], asset.location.gps.coordinates[0])
}

const recenter = () => {
  if (!map) return

  // No assets, center on default location
  if (!activeMapLayers.value.has(DefaultLayer.MARKER)) {
    map.setView(internalCenter.value, props.zoom ?? 7)
    return
  }

  // 1 asset, center on that asset
  if (mainAsset?.value) {
    map.setView(getAssetLatLng(mainAsset.value), props.zoom ?? 7)
    return
  }

  const layer = activeMapLayers.value.get(DefaultLayer.MARKER)! as L.FeatureGroup
  const bounds = layer.getBounds()

  map.fitBounds(bounds)
}

const toggleFullscreen = async () => {
  if (!fullscreenCapable.value) {
    return
  }

  if (typeof document !== 'undefined' && document.fullscreenElement === mapWrapper.value) {
    await document.exitFullscreen()
  } else {
    await mapWrapper.value?.requestFullscreen()
  }
}

const togglePdokLayer = (layerKey: string, authority: string, resource: string) => {
  if (!map) return

  if (activeMapLayers.value.has(layerKey)) {
    const layer = activeMapLayers.value.get(layerKey) as L.Layer
    map.removeLayer(layer)
    activeMapLayers.value.delete(layerKey)

    setLayerCookie(layerKey, false)
    return
  }

  addWmsLayer(layerKey, `https://service.pdok.nl/${ authority }/${ resource }/wms/v1_0?`)
  setLayerCookie(layerKey, true)
}

const toggleDuoppLayer = (layerKey: string) => {
  if (!map) return

  if (activeMapLayers.value.has(layerKey)) {
    const layer = activeMapLayers.value.get(layerKey) as L.Layer
    map.removeLayer(layer)
    activeMapLayers.value.delete(layerKey)

    setLayerCookie(layerKey, false)
    return
  }

  addWmsLayer(layerKey, `${duoppBaseUrl}/${duoppKey.value}`)
  setLayerCookie(layerKey, true)
}

const addWmsLayer = (layerKey: string, url: string) => {
  const wmsOptions: L.WMSOptions = {
    version: "1.3.0",
    format: "image/png",
    transparent: true,
    crs: L.CRS.EPSG4326,
    layers: layerKey,
    maxZoom: 20,
    // Set transparency for the satellite layer, so stuff like street names are still visible
    opacity: layerKey === DefaultLayer.SATELLITE ? 0.7 : 1,
    // Make sure the satellite layer is always on top of the base and other layers on top of that
    zIndex: layerKey === DefaultLayer.SATELLITE ? 2 : 3
  }

  const layer = L.tileLayer.wms(url, wmsOptions).addTo(map!)
  activeMapLayers.value.set(layerKey, layer)

  return layer
}

const setLayerCookie = (layerKey: string, valueToSet: boolean) => {
  useLocalStorage(`map-layer-${layerKey}`, false).value = valueToSet
}

const generateLeafletIcon = (iconSettings: IconSettings, highlight = false) => {
  const icon = generateMarkerIcon({ ...iconSettings, highlighted: highlight })

  return L.icon({
    iconUrl: icon.url,
    iconSize: [icon.scaledSize.width, icon.scaledSize.height],
    iconAnchor: [icon.anchor.x, icon.anchor.y],
  })
}

const onAssetInfowindowClosed = () => {
  const icon = generateLeafletIcon(selectedAsset.value!.iconSettings, false)
  selectedMarker.value?.setIcon(icon)

  selectedAsset.value = null
  selectedMarker.value = null
}

const generateMarker = (asset: MarkerAsset) => {
  const icon = generateLeafletIcon(asset.iconSettings, false)
  const coordinates = getAssetLatLng(asset)
  const marker = L.marker(coordinates, { icon, title: `${ asset.key } | ${ asset.description }` })

  marker.on("click", () => {
    // Reset icon of the previously selected marker
    if (selectedMarker.value) {
      const icon = generateLeafletIcon(selectedAsset.value!.iconSettings, false)
      selectedMarker.value.setIcon(icon)
    }

    // Update selected values
    selectedAsset.value = asset
    selectedMarker.value = marker

    // Highlight the focused marker
    const icon = generateLeafletIcon(asset.iconSettings, true)
    marker.setIcon(icon)
  })

  return marker
}

const generateClusteredAssetsLayer = (assets: Array<MarkerAsset>) => {
  const assetLayer = L.markerClusterGroup({
    disableClusteringAtZoom: 14,
    spiderfyOnMaxZoom: false
  })

  assets.forEach((asset) => {
    if (!asset.location?.gps) return

    const marker = generateMarker(asset)
    assetLayer.addLayer(marker)
  })

  return assetLayer
}

const processLayer = (layer: string, baseUrl: string) => {
  const enabled = useLocalStorage(`map-layer-${layer}`, false).value

  if (enabled) {
    addWmsLayer(layer, baseUrl)
  }
}

// Updates the nearby and asset relations when the main asset is set
watch(
  () => mainAsset?.value,
  async (newVal, oldVal) => {
    if (isEqual(oldVal, newVal)) return

    if (!mainAsset?.value || !hasScope(mainAsset.value.organization, AuthScope.CAN_VIEW_ASSET_RELATIONS)) {
      return
    }

    if (showMainAssetRelations?.value) {
      mainAssetRelations.value = await assetStore.getRelationsByAssetId(mainAsset.value._id)
    }

    // fetch new nearby assets when main asset changes
    if (mainAsset?.value.location?.gps?.coordinates && showNearby.value){
      nearbyAssets.value = await assetStore.getAssetsNearbyCoords(mainAsset.value.location.gps.coordinates[0], mainAsset.value.location.gps.coordinates[1], 1000)
    }
  },
  { deep: true, immediate: true }
)

watch(
  filteredAssets,
  () => {
    if (center?.value || !filteredAssets.value.length) {
      internalCenter.value = center?.value || defaultCoords
      return
    }
    if (mainAsset?.value && props.centerOnMainAsset) {
      internalCenter.value = geoPointToLatLng(mainAsset?.value.location?.gps)
      return
    }

    const assetCoordinates = filteredAssets.value
      .map((asset) => asset.location?.gps?.coordinates)
      .filter((coord): coord is [lng: number, lat: number] => !!coord)

    internalCenter.value = generateCenter(assetCoordinates)
  },
  { deep: true, immediate: true }
)

watch(showNearby, async () => {
  if (!showNearby.value || !mainAsset?.value?.location?.gps?.coordinates) {
    return
  }

  nearbyAssets.value = await assetStore.getAssetsNearbyCoords(internalCenter.value.lng, internalCenter.value.lat, 1000)
})

watch(mapReference, () => {
  if (!mapReference.value) return

  map = L.map(mapReference.value!, {
    zoomControl: false, // Removes the default + and - buttons
    attributionControl: false, // Removes the default attribution text
    minZoom: 2,

    // This solves this issue:
    // https://github.com/Leaflet/Leaflet/pull/8574
    // https://gis.stackexchange.com/questions/429698/leaflet-vue-3-issue-uncaught-typeerror-cannot-read-properties-of-null-this-m
    zoomAnimation: false,
    fadeAnimation: true,
    markerZoomAnimation: true
  }).setView(internalCenter.value, props.zoom ?? 7)

  const layer = L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
    noWrap: true, // Prevents repeating map when zooming out.
    keepBuffer: 3, // When panning the map, keep this many rows and columns of tiles before unloading them.
    minZoom: 2,
    maxZoom: 18 // Max zoom level that openstreetmap supports.
  }).addTo(map)

  activeMapLayers.value.set(DefaultLayer.BASE, layer)

  // Redraw the map when the window or element is resized (ex: show/hide the MapDrawer)
  const resizeObserver = new ResizeObserver(() =>
    setTimeout(() => {
      // Use setTimeout to prevent error 'ResizeObserver loop completed with undelivered notifications'.
      // https://github.com/juggle/resize-observer/issues/103#issuecomment-1711148285
      if (!map) return

      map.invalidateSize()
    }, 0)
  )

  resizeObserver.observe(mapWrapper.value as Element)

  map?.on("click", async ({ latlng }) => {
    const scaleFactor = Math.pow(2, map?.getZoom() || 0)
    mapFeatures.value = await getLayerFeatures(scaleFactor, latlng, activePdokLayers.value)
  })
}, { once: true })

watch(settings, () => {
  if (!settings.value || !map) return

  if (settings.value?.geoLayersDuoPP) {
    activeDuoppLayers.value.forEach((layer) => {
      processLayer(layer.key, `${duoppBaseUrl}/${duoppKey.value}`)
    })
  }

  if (settings.value?.geoLayers) {
    pdokGeoLayers.forEach((layer) => {
      processLayer(layer, pdokBaseUrl)
    })
  }
}, { deep: true, immediate: true })

watch([selectedAsset, showRelationLines, showMainAssetRelations],
  async () => {
    if (!map || !hasOrganizationWithScope(AuthScope.CAN_VIEW_ASSET_RELATIONS)) return

    // Remove existing relation lines
    if (activeMapLayers.value.has(DefaultLayer.RELATION)) {
      const layer = activeMapLayers.value.get(DefaultLayer.RELATION) as L.Layer
      map?.removeLayer(layer)
      activeMapLayers.value.delete(DefaultLayer.RELATION)
    }

    // Do this after we clear the map, so active relations are removed when disabling the option
    if (!showRelationLines.value) return

    let selectedAssetRelations: Array<PopulatedAssetRelation> = []

    // Only if user has permission and an asset is selected
    if (showMainAssetRelations?.value && mainAsset?.value) {
      selectedAssetRelations = await assetStore.getRelationsByAssetId(mainAsset.value._id)
    } else if (selectedAsset.value) {
      selectedAssetRelations = await assetStore.getRelationsByAssetId(selectedAsset.value._id)
    } else {
      return
    }

    if (!selectedAssetRelations.length) return

    const relationLayer = L.layerGroup()

    selectedAssetRelations.forEach((relation) => {
      if (!relation.asset1.location?.gps || !relation.asset2.location?.gps) return

      const start = L.latLng(relation.asset1.location.gps.coordinates[1], relation.asset1.location.gps.coordinates[0])
      const end = L.latLng(relation.asset2.location.gps.coordinates[1], relation.asset2.location.gps.coordinates[0])

      const polyline = L.polyline([start, end], {
        color: $theme.current.value.dark ? '#009cd1' : '#002440',
        weight: 2,
        smoothFactor: 1,
      })

      relationLayer.addLayer(polyline)
    })

    relationLayer.addTo(map)
    activeMapLayers.value.set(DefaultLayer.RELATION, relationLayer)
  }, { deep: true }
)

watch(assetsForMarkers, () => {
  if (!map) return

  let doRecenter = true

  if (activeMapLayers.value.has(DefaultLayer.MARKER)) {
    const layer = activeMapLayers.value.get(DefaultLayer.MARKER) as L.Layer
    map.removeLayer(layer)
    activeMapLayers.value.delete(DefaultLayer.MARKER)

    // Don't recenter the map when we're just updating the markers
    // only recenter on first load, except when there is a main asset
    // then always recenter on the main asset
    doRecenter = !!mainAsset.value
  }

  if (!assetsForMarkers.value.length) return

  const assetLayers = new L.FeatureGroup()

  // If we have multiple organizations, we want to show the clusters per organization
  for (const organizationId of currentOrganizationIds.value) {
    const organizationAssets = assetsForMarkers.value.filter((asset) => asset.organization === organizationId)
    if (!organizationAssets.length) continue

    const assetLayer = generateClusteredAssetsLayer(organizationAssets)

    // Update the active layers
    assetLayers.addLayer(assetLayer)
  }

  map.addLayer(assetLayers)

  // Update the active layers
  activeMapLayers.value.set(DefaultLayer.MARKER, assetLayers)

  // Rezoom map so all markers fit in the viewport
  // use setTimeout otherwise the recenter is done before the markers are fully loaded
  if (doRecenter) setTimeout(() => recenter(), 500)
}, { deep: true, immediate: true })
</script>

<style lang="scss">
.marker-cluster-small { background-color: rgb(0, 128, 204, 0.5); color: #fff; }
.marker-cluster-small div { background-color: rgba(0, 141, 191, 0.7); }

.marker-cluster-medium { background-color: rgb(0, 128, 204, 0.5); color: #fff; }
.marker-cluster-medium div { background-color: rgba(0, 140, 191, 0.7); }

.marker-cluster-large { background-color: rgb(0, 128, 204, 0.5); color: #fff; }
.marker-cluster-large div { background-color: rgba(0, 140, 191, 0.7); }

.marker-cluster {
	background-clip: padding-box;
	border-radius: 20px;
}

.marker-cluster div {
	width: 30px;
	height: 30px;
	margin-left: 5px;
	margin-top: 5px;
	text-align: center;
	border-radius: 15px;
	font: 12px "Helvetica Neue", Arial, Helvetica, sans-serif;
}

.marker-cluster span {
	line-height: 30px;
}
</style>